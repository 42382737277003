<template>
  <div class="home">
    <mice-navigation @vendor-list-mini="minimizeList"></mice-navigation>
    <top-nav-bar :mini="mini"></top-nav-bar>
  </div>
</template>

<script>
import {mapActions} from 'vuex';
const TopNavBar = () => import(/* webpackChunkName: "top-nav-bar" */'@/components/TopNavBar');
export default {
  name: 'Home',
  components: { TopNavBar },
  data: () => ({
    mini: false,
    navigation: 0
  }),
  methods: {
    minimizeList(status) {
      this.mini = status;
    },
    ...mapActions(['logout']),
  },
}
</script>

<style scoped>
#listing {
  width: 580px;
}

.details-content {
  width: calc(100vw - 480px);
  margin-left: 480px;
  height: 100vh;
  background-color: white;
}
</style>
